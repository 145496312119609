.container {
    display: flex;
    
    align-items: flex-start;
    justify-content: center;
   
    width: 100%;
  }
  .withBackground {
    padding: 12% 12% 0 12%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-size: 3360px auto;
    background-repeat: no-repeat;
    background-position:center 30%;
    background-image: url(../../../assets/img/oral.png),
      url(../../../assets//img/starsky.png);
    position: relative;

    margin-top: 184px;
    align-items: center;


  }
  
  .main {
    display: flex;
    flex-direction: column;
    /* height: 65vh; */
    width: 100%;
  }
  .hor {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageContainer {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:370px;
    width: 100%;
    max-width: 60em;
  }
  @media (max-width: 810px) {
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 55px;
    }
    .main {
      /* height: 160vh; */
    }
    .withBackground {
      width: 100%;
      /* height: 270vh; */
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url(../../../assets//img/starsky.png);
      position: relative;
    }
    .imageContainer {
      /* margin-top: 20vh; */
      gap: 10px;
    }
    .container {
      margin-top: 5vh;
    }
  }
  @media (max-width: 400px) {
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    /* .main {
      height: 160vh;
    } */
    .withBackground {
      width: 100%;
      gap:10px;
      /* height: 275vh; */
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url(../../../assets//img/starsky.png);
      position: relative;
    }
    /* .imageContainer {
      margin-top: 20vh;
    } */
    /* .container {
      margin-top: 10vh;
    } */
  }
  