.eap__teacher-student-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 60px;
}
/* 
.eap__teacher-student-item-container:nth-child(1) {
  width: 33%;
  flex-grow: 1;
}
.eap__teacher-student-item-container:nth-child(2) {
  width: 33%;
  flex-grow: 1;
}
.eap__teacher-student-item-container:nth-child(3) {
  width: 33%;
  flex-grow: 1;
} */
.eap__teacher-student-item-week {
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 40px;
}
.eap__student-item-head {
  display: flex;
  justify-content: flex-start;
  width: 25%;
}
.eap__student-item-body {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}

.eap__student-item-tail {
display: flex;
  justify-content: flex-end;
  text-align: end;
}
.eap__student-item-week-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: center;
  white-space: nowrap;
}
.eap__student-item-view-btn {
  background-color: #eabc7f;
  width: 103px;
  /* min-width: 103px;
  max-width: 103px; */
  min-height: 33px;
  border-radius: 33px;
  color: #ffffff;
  padding: 3px 25px;
  font-size: 20px;
  text-align: center;
  text-decoration: none!important;
}

.eap__student-item-view-btn:hover {
  background-color: #1b3e4d;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 601px) {
  .eap__student-item-week-component {
    display: none;
  }
}
