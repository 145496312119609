.container {
    display: flex;
    flex-direction: column;
}

.line1 {

}

.line2 {
    display: flex;
    flex-direction: row;
}

.q_card {

}

.q_card_main {
    padding: 20px 30px;
    border-radius: 10px;
    background-color: #f7f7f7;
    font-size: 16px;
    position: relative;
    z-index: 1 !important;
}

/*.q_card_main:before {*/
/*    position: absolute;*/
/*    z-index: -1!important;*/
/*    content: "";*/
/*    bottom:0;*/
/*    right:0;*/
/*    left:0;*/
/*    top: 0;*/
/*    background: #777;*/
/*    -webkit-box-shadow: 0 0px 0px #777;*/
/*    -moz-box-shadow: 0 0px 0px #777;*/
/*    !*box-shadow: 0 0px 0px #777;*!*/
/*    -webkit-transform: rotate(3deg);*/
/*    -moz-transform: rotate(3deg);*/
/*    -o-transform: rotate(3deg);*/
/*    -ms-transform: rotate(3deg);*/
/*    transform: rotate(3deg);*/
/*}.q_card_main:after {*/
/*    position: absolute;*/
/*    z-index: -1!important;*/
/*    content: "";*/
/*    bottom:0;*/
/*    right:0;*/
/*    left:0;*/
/*    top: 0;*/
/*    background: #777;*/
/*    -webkit-box-shadow: 0 0px 0px #777;*/
/*    -moz-box-shadow: 0 0px 0px #777;*/
/*    !*box-shadow: 0 0px 0px #777;*!*/
/*    -webkit-transform: rotate(-3deg);*/
/*    -moz-transform: rotate(-3deg);*/
/*    -o-transform: rotate(-3deg);*/
/*    -ms-transform: rotate(-3deg);*/
/*    transform: rotate(-3deg);*/
/*}*/
.q_card_after {
    position: absolute;
    display: block;
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    box-shadow: 0px 0px 0px 20px #000;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
}

.q_card_before {
    position: absolute;
    display: block;
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    box-shadow: 0px 0px 1px #000;
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    transform: rotate(-2deg);
}

#switch_component:hover {
    cursor: pointer!important;
}