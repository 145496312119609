.eap__icon-btn-container {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
/* a:hover {
  text-decoration: none;
} */

.eap__icon-btn-container:hover {
  cursor: pointer;
  color: #fcb216;
}
.eap__svg-icon,
.eap__svg-icon svg {
  display: flex;
  width: 30px;
  height: 33px;
  /* margin-left: auto;
  margin-right: auto; */
  align-items: center;
  justify-content: center;
}
.eap__svg-icon-img {
  min-width: 58px;
  max-width: 58px;
  min-height: 58px;
  max-height: 58px;
  border-radius: 58px;
}
.eap__icon-btn {
  border-radius: 58px 58px 58px 58px;
  min-width: 58px;
  max-width: 58px;
  min-height: 58px;
  max-height: 58px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
}
.eap__icon-btn-holder {
  max-width: 58px;
  justify-content: center;
}
.eap__icon-btn-text {
  margin-left: 15px;
}

@media screen and (max-width: 601px) {
  .eap__icon-btn {
    min-width: 33px;
    max-width: 33px;
    min-height: 33px;
    max-height: 33px;
    align-items: center;
  }
  .eap__svg-icon,
  .eap__svg-icon svg {
    width: 15px;
    height: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .eap__icon-btn-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .eap__icon-btn-text {
    margin-left: 0;
  }
}
