.image {
  width: 429px;
  /* width: 80%; */
}
.image2 {
  width: 30%;
}
.image3 {
  width: 70%;
}
.container,
.container2,
.container3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 60%; */
  /* max-width: 2510px; */

  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 10px;
  font-size: 24px;
  
  margin: 0 12%;
  gap: 30px;
}
/* .container3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
    align-items: center;
  }
  .container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    align-items: center;
    margin: 10px;
  } */
.text {
 
  font-weight: bold;
  font-size: 40;
  color: #4d4d4d;
  font-weight: 300;
  max-lines: 2;
  max-width: 55em;
  min-width: 20em;
}
@media screen and (max-width: 811px) {
  .container {
    width: 90%;
    margin-bottom: 50px;
    flex-direction: column;
  }
  .text {
    min-width: 0;
    
  }
}
