.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../../../assets/img/starsky.png);
  background-repeat: repeat;
  background-size: 50%;
  padding:6% 12% 6% 12%;
  gap: 105px;
}

