.button {
  background-color: #e5a44e;
  font-size: 30px;
  padding: 8px 60px;
  border-radius: 14px;
  color: #fff;

  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.2);
}
.button:hover {
  background-color: #64929f;
  color: #fff;
}

.line1{
  grid-column: 2 / span 2;
  grid-row: 1 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;

  align-items: center;
}
.container {
  margin-top: 6em;
  display: grid;
  grid-template-columns:80px 1fr 4fr 1fr;
  grid-template-rows: repeat(5, 150px);
  row-gap: 19px;
  column-gap: 10px;
}