.eap__management-card-container {
  border-radius: 20px 20px 20px 20px;
  /* background-color: blueviolet; */
  padding: 28px;
}
.eap__management-card-title {
  align-items: center;
  justify-content: space-between;
}


.eap__management-action {
  text-align: end;
}
.eap__scroll-view-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
