/* .eap__create_student_main_panel {
  display: flex;
  flex-direction: row;
 
} */
.eap__create_student_main_panel {
    display: grid;
    grid-template-columns: 1fr 1fr 120px 150px 100px 15px 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-self: center;
    row-gap: 10px;
    white-space: nowrap;
    column-gap: 25px;
}

.eap__create_student_main_panel .dropdown_wrapper.dropbtn {
    min-width: 222px;
    max-width: 453px;
}

.eap__create_student_main_panel input {
    width: 453px;
}

/* .eap__create-student-left-panel {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
    } */
.button_wrapper {
    grid-column: 4 / span 4;
    grid-row: 5 / span 1;
    display: flex;
    justify-content: flex-start;
    gap: 50px;
}

.eap__create_student_avatar_wrapper {
    width: 128px;
    height: 128px;
    border-radius: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.eap__create_student_avatar_wrapper svg {
    width: 63px;
    height: 71px;
}

/* .eap__create-student-right-panel {
      display: flex;
      flex-direction: column;
    } */

.elem1 {
    display: grid;
    grid-column: 1 / span 2;
    grid-row: 1 / span 4;
    align-self: center;
    justify-self: center;
}

.elem2 {
    grid-column: 1 / span 2;
    grid-row: 4 / span 1;
    justify-self: center;
}

.elem2:hover {
    cursor: pointer;
}

.elem3 {
    grid-column: 3 / span 1;
    grid-row: 6 / span 1;
    align-self: center;
    justify-self: start;
}

.elem4 {
    grid-column: 4 / span 2;
    grid-row: 6 / span 1;
    justify-self: stretch;
    width: 100%;
    display: flex;
}

.elem4 input {
    max-width: 222px !important;
}

.elem15 {
    grid-column: 7 / span 1;
    grid-row: 6 / span 1;
    justify-self: stretch;
}

.elem5 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    align-self: center;
    justify-self: center;
}

.elem6 {
    grid-column: 4 / span 4;
    grid-row: 7 / span 4;
    justify-self: stretch;
}

.elem7 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    align-self: center;
    justify-self: start;
}

.elem8 {
    grid-column: 4 / span 4;
    grid-row: 9 / span 1;
    align-self: center;
    justify-self: stretch;
}

.elem9 {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    justify-content: center;
    justify-self: start;
}

.elem10 {
    grid-column: 4 / span 4;
    grid-row: 8 / span 1;
}

.elem11 {
    grid-column: 4 / span 4;
    grid-row: 10 / span 1;
}

.elem12 {
    grid-column: 4 / span 4;
    grid-row: 2 / span 3;
    display: grid;
    justify-self: stretch;
}

.elem13 {
    grid-column: 4 / span 2;
    grid-row: 7 / span 1;
}

.elem14 {
    grid-column: 4 / span 2;
    grid-row: 11 / span 1;
}

.elem16 {
    grid-column: 4 / span 4;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.elem16 * {
    display: flex;
    width: 100% !important;
}

/* .elem16 input {
  width: 200px;
} */
.elem17 {
    grid-column: 3 / span 4;
    grid-row: 9 / span 1;
}

.elem18 {
    grid-column: 4 / span 1;
    grid-row: 11 / span 1;
}

.elem19 {
    grid-column: 3 / span 1;
    grid-row: 10 / span 1;
}

.elem20 {
    grid-column: 4 / span 1;
    grid-row: 10 / span 1;
}

.elem12 .member_wrapper {
    min-height: 128px;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 13px 20px;
    gap: 15px;
}

.chart_wrapper_label {
    grid-column: 3 / span 1;
    grid-row: 12 / span 1;
}

.chart_wrapper {
    grid-column: 4 / span 4;
    grid-row: 12 / span 2;
    background-color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    font-size: 16px;
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
}

.item1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.item2 {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}

.item3 {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

.item4 {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
}
