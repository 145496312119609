.eap__management-container {
  width: 100%;
  gap: 30px;
  padding-top: 20px;
}

.eap__landing-left {
  flex: 8;
  gap: 30px;
  display: flex;
}
.eap__landing-right {
  flex: 4;
  gap: 30px;
  display: flex;
}

.eap__management-card-title {
  display: flex;
  flex-direction: row;
}

@media  screen and (max-width: 768px) {
  .eap__management-container {
    flex-direction: column!important;
  }
  /* .eap__management-container :nth-child(1) {
          flex: 12;
        }
        .eap__management-container :nth-child(2) {
          flex: 12;
        } */
  /* .eap__landing-left,
  .eap__landing-right {
    flex:1;
    gap: 15px;
  } */
}
