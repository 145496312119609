.title {
    font-size: 32px;
    font-weight: bold;
    padding: 30px 0;
}

.main-panel {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.card {
    padding: 20px 20px;
    border-radius: 5px;
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, .1)
}

.school-list {
    margin-top:20px;
    display: flex;
    flex-flow: row wrap;
    gap:10px;
}

.school-item {
    width: 290px;
    padding: 30px 20px;
    border-radius: 5px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.school-logo-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.school-logo {
    object-fit:contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    outline: 5px solid #e4a34e;
    background-color: #cbcbcb;
}

.school-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-principal-btn {
    padding: 10px 20px;
    background-color: #e9bb7e;
    color: #fff;
    border-radius: 5px;
    transition: all 0.2s ease;
}

.add-principal-btn:hover {
    opacity: .7;
    transform: translateY(-2px);
    cursor: pointer;
}

.add-principal-btn:active {
    opacity: 1;
    transform: translateY(2px);
    cursor: pointer;
}
.forceset-button {
    border-radius: 5px;
    background-color: #e4a34e;
    color: #ffffff;
    cursor: pointer;
    padding: 5px 20px;
    margin-right: 5px;
}
.forceset-button:hover {
    opacity: .7;
}

.tr {
   padding: 10px!important;
    line-height: 30px;
}