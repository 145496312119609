/* .eap__create-student-main-panel {
  display: flex;
  flex-direction: row;
 
} */
.eap__create-student-main-panel {
  display: grid;
  grid-template-columns: 1fr 1fr 120px 200px 15px 15px 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-self: center;
  row-gap: 15px;
  column-gap: 5px;
}
.eap__create-student-main-panel .dropdown-wrapper.dropbtn {
  min-width: 222px;
  max-width: 453px;
}
.eap__create-student-main-panel input {
  width: 453px;
}
/* .eap__create-student-left-panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  } */

.eap__create-student-avatar-wrapper {
  width: 128px;
  height: 128px;
  border-radius: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.eap__create-student-avatar-wrapper svg {
  width: 63px;
  height: 71px;
}
/* .eap__create-student-right-panel {
    display: flex;
    flex-direction: column;
  } */

.class-management-elem1 {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
  align-self: center;
  justify-self: center;
}

.class-management-elem2 {
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  justify-self: center;
}
.class-management-elem3 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: start;
}
.class-management-elem4 {
  grid-column: 4 / span 2;
  grid-row: 1 / span 1;
  justify-self: stretch;
}
.class-management-elem4 input {
  max-width: 222px !important;
}
.class-management-elem15 {
  grid-column: 7 / span 1;
  grid-row: 1 / span 1;
  justify-self: stretch;
}

.class-management-elem5 {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  justify-self: center;
}
.class-management-elem6 {
  grid-column: 4 / span 4;
  grid-row: 2 / span 4;
  justify-self: stretch;
}

.class-management-elem7 {
  grid-column: 3 / span 1;
  grid-row: 6 / span 1;
  align-self: center;
  justify-self: start;
}
.class-management-elem8 {
  grid-column: 4 / span 4;
  grid-row: 4 / span 1;
  align-self: center;
  justify-self: stretch;
}
.class-management-elem9 {
  grid-column: 3 / span 1;
  grid-row: 8 / span 1;
  justify-content: center;
  justify-self: start;
}
.class-management-elem10 {
  grid-column: 4 / span 4;
  grid-row: 3 / span 1;
}
.class-management-elem11 {
  grid-column: 4 / span 4;
  grid-row: 5 / span 1;
}
.class-management-elem12 {
  grid-column: 4 / span 4;
  grid-row: 6 / span 2;
}
.class-management-elem12 .member-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 128px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 13px 20px;
  gap: 15px;
}
.class-management-elem13 {
  grid-column: 4 / span 2;
  grid-row: 7 / span 1;
}
.class-management-elem14-create-class {
  grid-column: 4 / span 2;
  grid-row: 11 / span 1;
}
.class-management-elem16 {
  grid-column: 4 / span 4;
  grid-row: 8 / span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.class-management-elem16 * {
  display: flex;
}
.class-management-elem16 input[type="date"] {
  max-width: 200px;
}
.class-management-elem17 {
  grid-column: 3 / span 4;
  grid-row: 9 / span 1;
}
.class-management-elem18 {
  grid-column: 4 / span 1;
  grid-row: 9 / span 1;
}
.class-management-elem19 {
  grid-column: 3 / span 1;
  grid-row: 10 / span 1;
}
.class-management-elem20 {
  grid-column: 4 / span 1;
  grid-row: 10 / span 1;
}
