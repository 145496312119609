.eap__wide-btn-container {
  display: flex;
  flex-direction: row;
  background-color: #eabc7f;
  align-items: center;
  padding: 12px 30px;
  border-radius: 20px;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  gap:10px;
}

.eap__wide-btn-container * {
  display: flex;
  white-space:nowrap;
}

.eap__wide-btn-container:hover {
  color:#FFFFFF;
  background-color:#1B3E4D;
    cursor: pointer;
}

@media screen and (max-width: 601px) {
  /* .eap__wide-btn-container {
    max-width: 120px;
    overflow: hidden;
  }
  .eap__wide-btn-container {} */
}