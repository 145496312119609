.eap__student_search_item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 73px;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 5px 20px;
}
.eap__student_search_item-wrapper .avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.eap__student_search_item-wrapper.avatar svg {
  height: 31px;
  width: 29px;
}
