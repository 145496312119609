.container {
    margin-top: 1em;
    min-height: 480px;
    background-color: #fff;
    border-radius: 20px;
    max-width: 989px;
    min-width: 989px;
    color: #1B3E4D;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}