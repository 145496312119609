.container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.sub_container {

    display: flex;
    flex-direction: row;
    gap: 20px;

}

.left_panel, .right_panel{
    display: flex;
    flex-direction: column;
}

.left_panel {
    width: 66%;
}

.right_panel {
    width: 33%;
}
@media screen and (max-width: 997px) {
    .sub_container {
        flex-direction: column;
    }

    .right_panel ,.left_panel {
        width: 100%;
    }
}