.navbar {
    background-color: #1b3e4d;
    color: white;
    padding-left: 100px;
  }
  .second {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 20%;
  }
  