.flex_container {
    padding: 10px;
    /*height: 100%;*/
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;

    column-gap: 1em;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 1em;
}

.flex_item {
    padding: 8px 30px;
    /*   margin-left: 10px;
      margin-top:10px;
       */
    display: flex;
    text-align: center;
    border-radius: 10px;
}

.flex_item:hover {
    cursor: pointer;
    transform: translateY(2px);
}