.modal_hide {
  display: none !important;
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* padding-top: 30%; Location of the box */
  padding-bottom: auto; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* padding-top: 30%; Location of the box */
  /*padding-bottom: auto; !* Location of the box *!*/
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* width: 100%; /* Full width */
  /* height: 100%; Full height */ 
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 0 0 0 0;
  border: 1px solid #888;
  border-radius: 20px;
  width: 353px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  background-color: #EABC7F;
  border: none;
  color: white;
  padding: 14px 29px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
}
.modal_content h1 {
  padding: 40px 40px 5px 40px;
  text-align: center;
  font-size: 20px;
  color: #1b3e4d;
}
.modal_content p {
  padding: 5px 40px 40px 40px;
  text-align: center;
  font-size: 14px;
  color: #727272;
}
.modal_content div {
  display: flex;
  min-height: 51px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  background-color: #dee4e6;
  border-radius: 0 0 20px 20px;
}
.modal_content div span:nth-child(1) {
  color: #1b3e4d;
}
/* .modal_content div span:nth-child(2) {
  color: #bf1b1b;
} */
.modal_text_delete {
  color: #bf1b1b;
}
.modal_text {
  color: #1b3e4d;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
