:root {
    --primary-audio-color: #1B3E4D;
    --border-audio-color: #707070;
    --track-height: 2px;
}

input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--track-height);
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--border-audio-color);
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
    /*box-shadow: 0px 0px 0px #000000;*/
    /*border: 1px solid var(--border-audio-color);*/
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--primary-audio-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--border-audio-color);
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: var(--track-height);
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--border-audio-color);
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
    /*box-shadow: 0px 0px 0px #000000;*/
    /*border: 1px solid var(--border-audio-color);*/
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--primary-audio-color);
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: var(--track-height);
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: var(--border-audio-color);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
    background: var(--border-audio-color);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    /*box-shadow: 0px 0px 0px #000000;*/
    /*border: 1px solid var(--border-audio-color);*/
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: var(--primary-audio-color);
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: var(--border-audio-color);
}

input[type=range]:focus::-ms-fill-upper {
    background: var(--border-audio-color);
}

.play_btn {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 10px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
}