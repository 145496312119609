/* .eap__create-student-main-panel-1 {
  display: flex;
  flex-direction: row;
 
} */
.eap__create-student-main-panel-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: stretch;
  row-gap: 25px;
  column-gap: 1px;
}
.eap__create-student-main-panel-1 .dropdown-wrapper.dropbtn {
  min-width: 222px;
  max-width: 453px;
}
.eap__create-student-main-panel-1 input {
  width: 453px;
}
/* .eap__create-student-left-panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
} */

.eap__create-student-avatar-wrapper {
  width: 128px;
  height: 128px;
  border-radius: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.eap__create-student-avatar-wrapper svg {
  width: 63px;
  height: 71px;
}
/* .eap__create-student-right-panel {
  display: flex;
  flex-direction: column;
} */

.student-manage-elem1 {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
  align-self: center;
  justify-self: center;
}

.student-manage-elem2 {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  justify-self: center;
}
.student-manage-elem3 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
}
.student-manage-elem4 {
  grid-column: 4 / span 3;
  grid-row: 1 / span 1;
  justify-self: stretch;
}
.student-manage-elem5 {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  justify-self: center;
}
.student-manage-elem6 {
  grid-column: 4 / span 3;
  grid-row: 2 / span 1;
  justify-self: stretch;
}
.student-manage-elem7 {
  grid-column: 3 / span 1;
  grid-row: 4 / span 1;
  align-self: center;
  justify-self: center;
}
.student-manage-elem8 {
  grid-column: 4 / span 3;
  grid-row: 4 / span 1;
  align-self: center;
  justify-self: stretch;
}
.student-manage-elem8 input[type="text"] {
  min-width: 453px;
  max-width: 453px;
}
.student-manage-elem9 {
  grid-column: 4 / span 2;
  grid-row: 3 / span 1;
}
.student-manage-elem10 {
  grid-column: 6 / span 2;
  grid-row: 3 / span 1;
}
.student-manage-elem10 .dropdown {
  margin-left: -40px;
}
.student-manage-elem11 {
  grid-column: 4 / span 2;
  grid-row: 5 / span 1;
}
.student-manage-elem12 {
  grid-column: 4 / span 2;
  grid-row: 6 / span 1;
}
.student-manage-elem13 {
  grid-column: 4 / span 2;
  grid-row: 7 / span 1;
}
.student-manage-elem14 {
  grid-column: 4 / span 2;
  grid-row: 9 / span 1;
}
.student-manage-program-dropdown {
  grid-column: 4 / span 2;
  grid-row: 8 /span 1;
}