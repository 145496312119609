.eap__button {
  text-align: center;
  justify-self: center;
  padding: 15px 42px;
  border: 0 solid #000000;
  font-size: 16px;
  border-radius: 20px;
  background-color: #ffffff;
}

.eap__button:hover {
  background-color: #628290 !important;
  color: #ffffff !important;
  cursor: pointer;
}
.eap__button.eap__button-primary {
  background-color: #eabc7f;
  color: #ffffff;
}
.eap__button.eap__button-primary:hover,
.eap__button:hover {
  background-color: #628290;
  color: #ffffff;
}
.rotate-center.loading {
  margin-left: auto;
  margin-right: auto;
}
