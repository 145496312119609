.eap__landing-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eap__landing-login-error {
    background-color: #f8d7da;
    padding: 2px 15px;
    color: #933841;
    border-radius: 5px;
    transform: translateY(10px);
}

.eap__landing-login-error.hide {
    background-color: #eabc7f;
    visibility: hidden;
}

.eap__landing-login-form-input {
    display: flex;
    flex-direction: column;
}

.eap__landing-login-form-input.password {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.eap__landing-login-form-input input {
    width: 245px;
}

.eap__landing-login-form-body {
    margin-top: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.eap__landing-login-form-body * {
    width: 245px;
}

.eap__landing-login-form-body input {
    height: 44px;
}

#landing-login-submit *:hover {
    background-color: #E5B343 !important;
}

.eap__landing-login-title {
    font-size: 52px;
    color: #fff;
}

.eap__landing-login-form-forgot-password:hover {
    cursor: pointer;
    color: #3e98c7;
}.eap__landing-login-form-forgot-password {
    cursor: pointer;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .eap__landing-login-form {
        flex-direction: column;
        align-items: center;
    }

    .eap__landing-login-form-body {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
    }

    .eap__landing-login-form-body * {
        width: 100%;
    }

    .eap__landing-login-form-body input {
        height: 44px;
    }

    .eap__landing-login-title {
        font-size: 30px;
        color: #fff;
    }
}

@media screen and (max-width: 1080px) {
    .eap__landing-login-form-body {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-top: 20px;
    }
}