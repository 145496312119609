.switch {
    position: relative;
    display: inline-block;
}
.switch-input {
    display: none;
}
.switch-label {
    display: block;
    width: 24px;
    height: 12px;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    user-select: none;
}
.switch-label::before,
.switch-label::after {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
}
.switch-label::before {
    width: 100%;
    height: 100%;
    background-color: #0f2129;
    border-radius: 9999em;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
}
.switch-label::after {
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #164559;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
}
.switch-input:checked + .switch-label::before {
    background-color: #e4a34e;
}
.switch-input:checked + .switch-label::after {
    left: 12px;
}