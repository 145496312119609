.card:hover {
    background-color: #f7f7f7;
    /*color: #f7f7f7;*/
    /*transform: scale(1.05,1.05);*/
    font-size: 30px;
    cursor: pointer;
}

.disabled-link:hover {
    cursor: not-allowed;
}

.disabled-link-pic:hover {
    cursor: not-allowed;
}