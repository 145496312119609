.eap__not-found-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-image: url(../../assets/img/planet&stars.png);
  object-fit: cover;
}
.eap__not-found-page h1 {
  font-size: 32px;
}
.eap__not-found-page * {
  transition: all 0.2s ease-in-out;
}
.eap__not-found-page .eap__link:hover {
  color: #628290;
  transform: translateY(-2px);
  cursor: pointer;
}
.eap__not-found-page .eap__link {
font-size: 40px;
}
