.container, .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.container {
    min-height: 100vh;
    background-color: #1B3E4D;
}

.form {
    width: 400px;
    padding: 30px;
    background-color: #456979;
    border-radius: 5px;
    color:#1B3E4D;
}

.title {
    color:#1B3E4D;
    font-size: 32px;
    font-family: Futura-Heavy;
}
.error-msg {
    background-color: #EB7777;
    padding:5px 10px;
}
.success-msg {
    background-color: #68D074;
    padding:5px 10px;
}
.input-box {
    background-color: #1f4c5e;
    color:#fdfdfd;
}

.btn:hover {
    cursor: pointer;
    transform: translateY(-2px);
    background: #1f4c5e;
    color: #fff;
}

.btn {
    background-color: #1B3E4D;
    color: #fdfdfd;
    font-size: 24px;
}