.dropdown-wrapper_1,
dropdown-wrapper_1 * {
  display: flex;
}
div.dropdown-wrapper_1 {
  z-index: 1;
  max-width: 210px;
  border-radius: 20px;
  height: 48px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  background: #628290;
}
.dropdown-wrapper_1 * {
  z-index: 0;
}
.dropbtn_1 {
  background-color: #ffffff;
  color: #000000;
  /* padding: 16px; */
  padding: 0 4px 0 21px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn_1:hover,
.dropbtn_1:focus {
  background-color: #82ABBE;
  color: #ffffff;
}

.dropdown_1 {
  position: relative;
  display: inline-block;
}

.dropdown-content_1 {
  display: none;
  position: absolute;
  margin-top: 10px;
  border-radius: 20px;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content_1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content_1 a:hover {
  background-color:#82ABBE;
  color: #ffffff !important;
}

.dropdown_1 a:hover {
  background-color:#82ABBE;
  color: #ffffff;
}

/* .dropdown-content_1 div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.dropdown-content_1 div:hover {
  background-color: #1b3e4d;
  color: #ffffff !important;
}

.dropdown div:hover {
  background-color: #1b3e4d;
  color: #ffffff;
} */

.show {
  display: block;
}
.dropdown-text_1 {
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
}
[disabled].dropdown_1 {
    background-color: #333333;
}