/* h-screen bg-eapLogin flex flex-row justify-center items-center bg-login bg-cover sm:flex-col */
.container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  column-gap: 150px;

  background-image: url(../../assets/img/planet.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a3e4e;
}
.login_form {
}

.logo * {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /*.logo {*/
  /*  position: absolute;*/
  /*  display: flex;*/
  /*  width: 50%;*/
  /*  left: 0;*/
  /*  right: 0;*/
  /*}*/
  .logo * {
    min-width: 80%;
    max-width: 80%;
  }

}

@media screen and (max-width: 1193px) {
  .container {
    flex-direction: column;
    padding-top: 6%;
    padding-bottom: 6%;
    gap: 50px;
  }
}
