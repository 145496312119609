.container {
  height: 50em;
  background-color: #0a3e4e;
  background-size: contain;
  position: relative;
  display: flex;
  margin-top: 100px;
  align-self: center;
  
  justify-self: center;
  justify-content: center;
  
}

.custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom svg {
  position: relative;
  display: block;
  width: calc(155% + 1.3px);
  height: 173px;
}

.custom .shape {
  fill: #f5f5f5;
  transform: translateY(-1px);
}
.main {
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100em;
  /* min-width: 1360px; */
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.first {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  width: 50%;
  justify-content: space-between;
}
.btn {
  min-width: 160px;
  background-color: #33d668;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.btn:hover {
  background-color: #30c1d6;
}
.second {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-top: 20px;
}
.vert {
  display: flex;
  flex-direction: column;
}
.list {
  list-style-type: none;
  margin-top: 20px;
  padding: 0;
}
.list li {
  float: left;
  padding-right: 10px;
}
.list li a {
  color: inherit;
  text-decoration: inherit;
}
.list li a:hover {
  color: #30c1d6;
}
.follow {
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  margin-top: 20px;
  align-items: flex-start;
}
.pLeft {
  padding-right: 40px;
}
.pLeft *:hover {
  fill: #30c1d6;
}
@media (max-width: 800px) {
  .follow {
    width: 70%;
  }
  .first {
    width: 70%;
  }
  .second {
    width: 70%;
  }
 
}
@media (max-width: 400px) {
  .follow {
    width: 90%;
  }
  .first {
    width: 90%;
  }
  .second {
    width: 90%;
  }
 
}
