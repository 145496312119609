.eap__headerbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  padding-left: var(--header-side-padding);
  padding-right: var(--header-side-padding);
  /* justify-content: space-between; */
  height: var(--header-container-height);
  background-color: var(--header-container-color);
  z-index: 9999;
}
.eap__spacer {
  flex: 1;
}
.eap__headerbar-items {
  flex: auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.eap__message-btn-holder {
  margin-right: 30px;
  
}
.eap__message-btn-holder svg{
  /* margin-right: 30px; */
  width: 42px;
}
.eap__headerbar-items-logo {
  display: flex;
  align-items: center;
}
.eap__headerbar-items-logo:hover {
  cursor: pointer;
}
.eap__headerbar-items-logo p {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 20px;
  padding-left: 18px;
  white-space: nowrap;
  overflow: hidden;
  padding-right:10px ;
}

.eap__headerbar-items-logo img {
  display: block;
  height: var(--header-logo-height);
  min-width: 138px;
}

.eap__headerbar-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.eap__profile-icon-holder {
  margin-right: 0;
}

@media only screen and (min-width: 600px) {
  /* body {
      background-color: blue;
    } */

  .eap__headerbar-items-container {
    min-width: 185px;
  }

  .eap__headerbar-items-container-mobile {
    display: none;
  }
  .eap__headerbar-items-container-desktop {
    display: flex;
  }
}




@media only screen and (max-width: 601px) {
  .eap__headerbar {
    padding-left: var(--header-side-padding-mobile);
    padding-right: var(--header-side-padding-mobile);
    height: var(--header-container-height-mobile);
  }
  .eap__message-btn-holder {
    margin-right: 0;
  }

  .eap__headerbar-items-logo img {
    display: block;
    height: 48px;
    min-width: 100px;
  }
  .eap__headerbar-items-logo p {
    display: none;
  }

  .eap__headerbar-items-logo {
    order: 2;
  }
  .eap__headerbar-item-icon-holder {
    background-color: #ffffff;
  }
  .eap__profile-icon-holder {
    display: flex;
    order: 1;
  }
  .eap__message-btn-holder {
    display: flex;
    order: 3;
  }
  .eap__spacer {
    display: none;
  }
  /* body {
      background-color: lightblue;
    } */
}
