.teacher_create_template_line_1 {
  display: flex;
  flex-direction: row;
  justify-self: start;
  justify-content: space-between;
  /*gap: 110px;*/
  align-items: center;

  color: #1b3e4d;
  margin-top: 3em;
  margin-bottom: 2em;
}
.teacher_create_template_line_1 h1 {
  font-size: 30px;
}

.back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:110px;
}

.teacher_create_template_line_2 {
  margin-top: 30px;
}
body {
  background-color: #f5f5f5;
}
.container {
  padding-top: 50px;
}
