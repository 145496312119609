.eap__pagination-wrapper * {
    display: flex;
    height: 33px;
  }
  .eap__pagination-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 1px;
  }
  .eap__pagination-previous:hover {
    cursor: pointer;
  }
  .eap__pagination-next:hover {
    cursor: pointer;
  }
  .eap__pagination-next svg, .eap__pagination-previous svg {
    width: 33px;
    height: 33px;
  }
  .eap__pagination-current-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #628290;
    /*padding: 0 5px 0 16px;*/
    padding: 5px 16px;
    color: #ffffff;
    border-radius: 20px;
    white-space: nowrap;
  }
  .eap__pagination-current-page p {
    padding-top: 2px;
    justify-self: center;
    text-align: center;
    white-space: nowrap;
  }
  .eap__pagination-current-page-dropdown svg {
    height: 23px;
    margin-left: 10px;
    border-radius: 23px;
    align-self: center;
  }
  