/*announce modal */
.announce_modal {
  /* display: none; */
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  /* padding-top: min(218px, 25%); Location of the box */
  /* padding-bottom: min(218px, 5%); Location of the box */
  left: 0;
  top: 0;
  /* pointer-events: none; */
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  /*overflow: hidden; !* Enable scroll if needed *!*/
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  color: #1b3d4d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message_list {
  height: calc(100% - 218px);
}

.announce_modal_content {
  width: 60%;
  height: 80%;
  margin-left: auto;
  margin-right: auto;
  /* height: min(840px, 80%); */
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  pointer-events: all;
  border-radius: 20px;
  overflow: hidden;
  /*overflow: scroll;*/
}

.announce_modal_content_add {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  /* height: min(840px, 80%); */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  padding: 20px;
}

.announce_modal_content_left {
  display: flex;
  flex-direction: column;
  width: min(100%, 38%);
  padding: 1em;
}

.add_announce_content {
  margin-top: 10px;
  font-size: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  column-gap: 30px;
  row-gap: 20px;
  align-items: center;
  justify-items: flex-start;
  border-radius: 20px;
}

.a_topic_label {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.a_topic_input {
  grid-column: 2 / span 4;
  grid-row: 1 / span 1;
}

.a_target_label {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.a_target_dropdown {
  grid-column: 2 / span 3;
  grid-row: 2 / span 1;
}

.a_message_label {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}

.a_message_input {
  grid-column: 2 / span 4;
  grid-row: 3 / span 9;

  height: 100%;
  resize: none;
}

.a_message_submit {
  grid-column: 3 / span 3;
  grid-row: 12 / span 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #628290;
  padding: 8px 10px;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.a_message_submit:hover {
  background-color: #82abbe;
  cursor: pointer;
}

.announce_add_btn {
  display: flex;
  gap: 20px;
  text-transform: capitalize;
  font-size: 20px;
  background-color: #e4e4e4;
  padding: 10px 20px;
  border-radius: 20px;
  align-items: center;
  white-space: nowrap;
  flex: 0;
}

.announce_add_btn:hover {
  background-color: #628290;
  color: #ffffff;
  fill: #ffffff;
  cursor: pointer;
}

.announce_modal_content_right {
  display: flex;
  flex-direction: column;
  width: min(100%, 62%);
  background-color: #ededed;
  padding: 2em;

  border-radius: 0 20px 20px 0;
  gap: 20px;
}

.searchbar input {
  background-color: #e4e4e4;
  width: calc(100% + 30px);
  min-width: 100%;
}

.title1 {
  font-size: 30px;
}

.message_item:hover {
  cursor: pointer;
  background-color: #628290;
  color: #f7f7f7;
}

@media screen and (max-width: 600px) {
  .announce_modal_content * {
    font-size: 16px;
  }

  .title1 {
    font-size: 20px;
    white-space: normal;
  }

  .message_list {
    height: calc(100% - 228px);
  }
}

@media screen and (max-width: 1575px) {
  .announce_modal_content {
    width: 90%;
  }
}

@media screen and (max-width: 1029px) {
  .announce_modal_content {
    flex-direction: column;
  }

  .announce_modal_content_left,
  .announce_modal_content_right {
    width: 100%;
    height: 50%;
  }

  .announce_modal_content_right {
    border-radius: 20px;
    order: 1;
  }

  .announce_modal_content_left {
    order: 2;
  }
}
