.container {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 3em;
    color: #1b3d4d;
}

.container h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #1b3d4d;

}

.info_container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
}

.video_container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.video_iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.disabled_related_video:hover {
    cursor: not-allowed;
}

.enabled_related_video :hover {
    cursor: pointer;
}

.small_video_container {
    position: relative;
    max-width: 340px;
    min-width: 340px;
    max-height: 260px;
    min-height: 260px;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.small_video_iframe {
    max-height: 175px;
    min-height: 175px;
    max-width: 340px;
    min-width: 340px;
    object-fit: cover;

    border-radius: 20px;
    border: none;
}

.comment_container {

}

.related_video_container {

}