.wrapper {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.line_1 {
    display: flex;
    flex-direction: row;
    justify-self: start;
    gap: 110px;
    align-items: flex-start;
    color: #1b3e4d;
    margin-top: 3em;
    /*margin-bottom: 2em;*/


}

.line_1 h1 {
    font-size: 80px;
}

.title_holder {
    display: flex;
    background-image: url(../../../assets/svg/question_title_bg.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 5% 50%;
    min-height: 250px;
    width: 100%;
    align-items: center;
    gap: 5%;
}

.line_2 {
    /*margin-top: 30px;*/
    display: flex;
    width: 90vw;
    justify-content: center;
}

.container {
    min-height: 480px;
    background-color: #fff;
    border-radius: 20px;
    color: #1B3E4D;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.left_block {
    min-width: 8em;
}

.right_block {
    margin-top:30px;
    width: 100%;
    max-width: 989px;
    display: flex;
    flex-direction: column;

}

.instruction_btn {

}

.bookmark_btn {

}

.instruction_btn:hover, .bookmark_btn:hover, .next_btn:hover , .notepad_btn:hover{
    cursor: pointer;
    opacity: 0.5;
    transform: translateY(2px);
}

.next_btn {
    padding: 5px 50px;
    background-color: #0a3e4e;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    font-size: 20px;
    box-shadow: 5px 5px 5px 0px rgba(27, 62, 77, 0.2);
}

.next_btn_disabled {
    padding: 5px 50px;
    background-color: #f7f7f7;
    color: #0a3e4e;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    font-size: 20px;
    box-shadow: 5px 5px 5px 0px rgba(27, 62, 77, 0.2);
}

.next_btn_disabled:hover {
    cursor: not-allowed;
}

.notepad_btn svg {
    max-height: 41px;
    min-height: 41px;
    max-width: 41px;
    min-width: 41px;
}