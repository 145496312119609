.phone-textfield-wrapper {
  /* margin: 229px auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* .phone-textfield-wrapper input[type="number"] {
} */
/* 
.phone-textfield-wrapper div {
  position: relative;
  display: flex;
  height: 4rem;
  border: 0;
  border-radius: 4rem;
  background: #fff;
} */
.phone-textfield-wrapper span {
  /* position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: grid;
  place-items: center;
  height: 3rem;
  width: 6rem;
  font-size: 0.875rem;
  border-radius: inherit;
  background: #3abced;
  color: #fff;
  pointer-events: none;
  transition: all 0.35s;
  letter-spacing: 1px; */
  display: flex;
  max-width: 107px;
  min-width: 107px;
  height: 45px;
  background-color: #e6e6e6;
  text-align: center;
  justify-content: center;
  vertical-align: center;
  padding: 00px 30px;
  border-radius: 45px;
  font-size: 14px;
  z-index: 1;
  align-items: center;
}
.phone-textfield-wrapper input {
  transform: translateX(-107px);
  z-index: 0;
  text-indent: 120px;
  /* flex: 1 1 auto;
  padding-left: 6.5rem;
  height: inherit;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 1rem;
  text-indent: 0.625rem;
  transition: all 0.35s; */
}
.phone-textfield-wrapper input::placeholder {
  color: #9b9ba5;
}
/* .phone-textfield-wrapper input:focus {
  transform: translateX(-6rem);
} */
/* .phone-textfield-wrapper input:focus ~ span {
  transform: translateY(-4rem);
} */
