.container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 200px 1fr 1fr 1fr 1fr 200px;
    grid-template-rows: 1fr 1fr 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.container > label {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    font-size: 20px;
}

.avatar {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.avatar_holder {
    min-width: 128px;
    min-height: 128px;
    max-height: 128px;
    max-width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.avatar_holder img {
    object-fit: cover;
    min-width: 128px;
    min-height: 128px;
    max-height: 128px;
    max-width: 128px;
    border-radius: 50%;
}

.name {
    font-size: 20px;
}

.phone_label {
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
}

.phone {
    grid-column: 5 / span 2;
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email_label {
    grid-column: 8 / span 1;
    grid-row: 1 / span 1;
}

.email {
    grid-column: 9 / span 2;
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.class_label {
    grid-column: 4 / span 1;
    grid-row: 2 / span 1;
}

.class_name {
    grid-column: 5 / span 2;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.school_label {
    grid-column: 8 / span 1;
    grid-row: 2 / span 1;
}

.school_name {
    grid-column: 9 / span 2;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change_pass_label {
    grid-column: 4 / span 2;
    grid-row: 4 / span 1;
}

.current_pass_label {
    grid-column: 4 / span 2;
    grid-row: 5 / span 1;
}

.current_pass {
    grid-column: 6 / span 6;
    grid-row: 5 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_pass_label {
    grid-column: 4 / span 2;
    grid-row: 6 / span 1;
}

.new_pass {
    grid-column: 6 / span 6;
    grid-row: 6 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.re_new_pass_label {
    grid-column: 4 / span 2;
    grid-row: 7 / span 1;
    white-space: nowrap;
}

.re_new_pass {
    grid-column: 6 / span 6;
    grid-row: 7 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm_btn {
    grid-column: 4 / span 2;
    grid-row: 8 / span 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.confirm_btn_body {
    font-size: 20px;
    background-color: #1b3d4d;
    color: #fff;
    border-radius: 20px;
    padding: 10px 3em;
}

.confirm_btn_body:hover {
    background-color: #89b481;
    color: #fff;
    border-radius: 20px;
    padding: 10px 3em;
    cursor: pointer;
}

.class_overview_label {
    grid-column: 1 / span 2;
    grid-row: 9 / span 1;
}

.class_overview_label_1 {
    grid-column: 4 / span 8;
    grid-row: 9 / span 1;
}

.class_overview {
}

.class_overview_container {
}

.student_progression_label {
    grid-column: 1 / span 4;
    grid-row: 13 / span 1;
}

.student_score_label {
    grid-column: 1 / span 4;
    grid-row: 17 / span 1;
}

.time_spend {
    grid-column: 4 / span 8;
    grid-row: 10 / span 4;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 30px;
    max-height: 327px;
    padding: 50px 10%;
}

.student_progression {
    grid-column: 4 / span 8;
    grid-row: 14 / span 3;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 30px;

    padding: 50px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);

    position: relative;
}

.progress1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
}

.progress2 {
    grid-column: 3 / span 2;
    grid-row: 1 / span 1;
}

.progress3 {
    grid-column: 1 / span 3;
    grid-row: 3 / span 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20%;
}

.progress4 {
    grid-column: 4 / span 1;
    grid-row: 3 / span 1;
    color: #eabc7f;
    font-size: 16px;
}

.progress_line {
    position: absolute;
    bottom: 50%;

    left: 50px;
    right: 50px;

    border-bottom: 1px solid #000;
}

.student_score {
    grid-column: 4 / span 8;
    grid-row: 18 / span 18;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px;
    align-items: center;
    background-color: #fff;
    border-radius: 30px;
}

.week_dropdown_item_list {
    position: absolute;
    background-color: #1B3E4D;
    right: 0;
    top: 40px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
}

.week_dropdown_item {
    color: #fff;
    width: 150px;
    padding: 5px 10px;
}

.week_dropdown_item:hover {
    color: #1b3d4d;
    background-color: #fdfdfd;
    cursor: pointer;
}