/*.container {*/
/*  margin-top: 6em;*/
/*  display: grid;*/
/*  grid-template-columns:80px 1fr 4fr 1fr;*/
/*  grid-template-rows: repeat(5, 150px);*/
/*  row-gap: 19px;*/
/*  column-gap: 10px;*/
/*}*/
/*.container p {*/
/*  font-size: 16px !important;*/
/*  color: #1b3e4d;*/
/*}*/
/*.container h1 {*/
/*  font-size: 20px !important;*/
/*  color: #1b3e4d;*/
/*}*/
/*.head{*/
/*  display: flex;*/

/*  flex-direction: column;*/
/*  justify-content: space-between;*/
/*}*/
/*.active {*/
/*  background-color: #89b481;*/
/*  max-width: 115px;*/
/*  min-width: 115px;*/
/*  height: 97px;*/
/*  border-radius: 15px;*/

/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/

/*  font-size: 16px;*/
/*  color: #fff;*/
/*}*/
/*.inactive {*/
/*  background-color: #b0b0b0;*/
/*  max-width: 115px;*/
/*  min-width: 115px;*/
/*  height: 97px;*/
/*  border-radius: 15px;*/

/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/

/*  font-size: 16px;*/
/*  color: rgb(110, 103, 103);*/
/*}*/

/*!* .eap__teacher-weekly-content-wrapper .item0 {*/
/*  display: grid;*/
/*  grid-column: 1 / span 1;*/
/*  grid-row: 1 / span 1;*/
/*} *!*/
/*.line1 {*/
/*  grid-column: 2 / span 2;*/
/*  grid-row: 1 / span 1;*/
/*  padding: 20px;*/
/*  background-color: #fff;*/
/*  border-radius: 20px;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 4fr;*/
/*  grid-template-rows: 1fr;*/
/*  column-gap: 10px;*/

/*  align-items: center;*/
/*}*/
/*.divider1 {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  border-right: 1px solid black;*/
/*}*/
/*.first_week_label {*/
/*  position: relative;*/
/*  display: grid;*/
/*  grid-column: 1 / span 1;*/
/*  grid-row: 1 / span 1;*/
/*  padding-right: .5em;*/
/*}*/
/*.first_week {*/
/*}*/
/*.reminder1 {*/
/*  display: grid;*/
/*  grid-column: 3 / span 1;*/
/*  grid-row: 1 / span 1;*/
/*  padding: 25px;*/
/*}*/
/*.reminder_wrapper {*/
/*  display: flex;*/
/*  background-color: burlywood;*/
/*  border-radius: 20px;*/
/*  width: 149px;*/
/*  align-items: center;*/
/*  justify-content: flex-start;*/
/*  padding: 15%;*/
/*  font-size: 20px;*/
/*  color: #fff;*/
/*  text-transform: capitalize;*/
/*}*/
/*.reminder2 {*/
/*  display: grid;*/
/*  grid-column: 3 / span 1;*/
/*  padding: 25px;*/
/*  grid-row: 2 / span 1;*/
/*}*/

/*.line2 {*/
/*  grid-column: 2 / span 2;*/
/*  grid-row: 2 / span 1;*/
/*  padding: 20px;*/
/*  background-color: #fff;*/
/*  border-radius: 20px;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 4fr;*/
/*  grid-template-rows: 1fr;*/
/*  column-gap: 10px;*/

/*  align-items: center;*/
/*}*/
/*.line3 {*/
/*  grid-column: 2 / span 2;*/
/*  grid-row: 3 / span 1;*/
/*  padding: 20px;*/
/*  background-color: #fff;*/
/*  border-radius: 20px;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 4fr;*/
/*  grid-template-rows: 1fr;*/
/*  column-gap: 10px;*/

/*  align-items: center;*/
/*}*/
/*.line4 {*/
/*  grid-column: 2 / span 2;*/
/*  grid-row: 4 / span 1;*/
/*  padding: 20px;*/
/*  background-color: #fff;*/
/*  border-radius: 20px;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 4fr;*/
/*  grid-template-rows: 1fr;*/
/*  column-gap: 10px;*/

/*  align-items: center;*/
/*}*/
/*.line5 {*/
/*  grid-column: 2 / span 2;*/
/*  grid-row: 5 / span 1;*/
/*  padding: 20px;*/
/*  background-color: #fff;*/
/*  border-radius: 20px;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 4fr;*/
/*  grid-template-rows: 1fr;*/
/*  column-gap: 10px;*/

/*  align-items: center;*/
/*}*/

.container {
  margin-top: 6em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;

  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: repeat(5, 150px);
  row-gap: 19px;
  column-gap: 10px;
  max-width: 870px;
}

.container p {
  font-size: 16px !important;
  color: #1b3e4d;
}

.container h1 {
  font-size: 20px !important;
  color: #1b3e4d;
}

.head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.active {
  background-color: #89b481;
  max-width: 115px;
  min-width: 115px;
  height: 97px;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  color: #fff;
}

.inactive {
  background-color: #b0b0b0;
  max-width: 115px;
  min-width: 115px;
  height: 97px;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  color: #fff;
}

/* .eap__teacher-weekly-content-wrapper .item0 {
  display: grid;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
} */
.line1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;

  align-items: center;
}

.divider1 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid black;
}

.first_week_label {
  position: relative;
  display: grid;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  padding-right: .5em;
}

/* .first_week {
} */
.reminder1 {
  display: grid;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  padding: 25px;
}

.reminder_wrapper {
  display: flex;
  background-color: burlywood;
  border-radius: 20px;
  width: 149px;
  align-items: center;
  justify-content: flex-start;
  padding: 15%;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
}

.reminder2 {
  display: grid;
  grid-column: 3 / span 1;
  padding: 25px;
  grid-row: 2 / span 1;
}

.line2 {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;

  align-items: center;
}

.line3 {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;

  align-items: center;
}

.line4 {
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;

  align-items: center;
}

.line5 {
  grid-column: 1 / span 2;
  grid-row: 5 / span 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  column-gap: 10px;
  align-items: center;
}
