.eap__teacher-student-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60px;
}
/* 
.eap__teacher-student-item-container:nth-child(1) {
  width: 33%;
  flex-grow: 1;
}
.eap__teacher-student-item-container:nth-child(2) {
  width: 33%;
  flex-grow: 1;
}
.eap__teacher-student-item-container:nth-child(3) {
  width: 33%;
  flex-grow: 1;
} */

.eap__messenger-text-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 25%);
}
.eap__messenger-text-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.eap__messenger-text-inline * {
  display: flex;
}
.eap__messenger-text-item {
  width: 100%;
}
.eap__svg-icon {
  align-items: center;
  justify-self: center;
}