.flex_container {
    padding: 10px;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    row-gap: 10px;
    /*column-gap: 10px;*/

    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex_item {
    padding: 5px 0;
    width: 40%;
    /*   margin-left: 10px;
      margin-top:10px;
       */

    text-align: center;
}

.flex_container_2 {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    width: 100%;
}

.flex_item_2 {
    /*max-width: 45%;*/
    /*min-width: 40%;*/
    background-color: #f7f7f7;
    text-align: start;
    padding: 10px 20px;
    border-radius: 10px;
}

.flex_item_2:hover {
    cursor: pointer;
    transform: translateY(2px);
}