.eap__messenger-page-container {
  display: flex;
  flex-direction: row;
}

.eap__messenger-page-container .chatlist-panel {
  display: flex;
  flex-direction: column;
  width: 425px;
}

.eap__messenger-page-container .chatlist-panel .chatlist-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30%;
  justify-content: flex-start;
  padding: 0 25px;
}
.chatlist-search-box {
  position: relative;
}
.chatlist-search-box .chatlist-search-auto-fill {
  position: absolute;
  top: 4em;
  width: 20vh;
  padding: 0.5em 1.5em;
  border-radius: 20px;
  background-color: #f5f5f5;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: large;
}

.chatlist-search-box .chatlist-search-auto-fill *:hover {
  color: #456979;
  cursor: pointer;
}
.eap__messenger-page-container .chatlist-panel .chatlist-search-box {
  padding: 0 25px;
}
.eap__messenger-page-container .chatlist-panel .chatlist-search-box input {
  min-width: calc(100%);
  max-width: calc(100%);
  background-color: #ededed;
}

.eap__messenger-page-container .chat-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.eap__messenger-page-container .chat-panel .chat-name {
  display: flex;
}

.eap__messenger-page-container .chat-panel .chat-content {
  display: flex;
  flex: 1;
}

.eap__messenger-page-container .chat-panel .chat-input {
  display: flex;
}
.eap__messenger-page-container .chat-panel .chat-input input {
  width: 100%;
}

.eap__messenger-page-container {
  height: calc(100vh - var(--header-container-height));
}
