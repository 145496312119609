.chat-avatar-wrapper {
  position: relative;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 65px;
}

.chat-avatar-wrapper .chat-avatar-status.online {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background-color:#4EB246;
}
.chat-avatar-wrapper .chat-avatar-status.offline {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background-color:#C11616;
}
