.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 50px auto auto auto;
  max-width: 970px;
  /*height: 90vh;*/
  column-gap: 50px;
  row-gap: 40px;
}

.btn1 {
  grid-row: 1 / span 1;
  grid-column: 1 / span 3;
}
.btn2 {
  grid-row: 1 / span 1;
  grid-column: 4 / span 3;
}

.btn3 {
  grid-row: 1 / span 1;
  grid-column: 7 / span 3;
}
.searchbar {
  grid-row: 2 / span 1;
  grid-column: 1 / span 6;
}
.filters {
  grid-row: 3 / span 2;
  grid-column: 1 / span 8;

  border-radius: 20px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  /* overflow-y: scroll; */
}
.line {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  border-bottom: 1px dashed #333;
}
.filter_class {
  border-radius: 20px;
  background-color: #f5f5f5;
  padding: 2px 10px;
  margin-left: 10px;
}
.filter_group {
  border-radius: 20px;
  background-color: #f5f5f5;
  padding: 2px 10px;
  margin-left: 10px;
}
.search_outcome_label {
  grid-row: 5 / span 1;
  grid-column: 1 / span 8;
  font-size: 20px;
}
.search_outcome {
  grid-row: 6 / span 5;
  grid-column: 1 / span 9;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 25px;
  max-width: 100%;
}
.search_result {
  background-color: #fff;
  display: flex;
  font-size: 16px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
  /* align-items: center; */
  justify-self: center;
}
.edit_btn {
  /* grid-row: 12 / span 2;
  grid-column: 6 / span 2; */
  
}
.view_btn {
  background-color: #eabc7f;
  padding: 2px 30px;
  border-radius: 15px;

  color: #fff;
}

.view_btn:hover {
  background-color: #1b3e4d;
  cursor: pointer;
}
.edit_btn_body {
  /* grid-row: 12 / span 2;
  grid-column: 6 / span 2; */
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pagination {
  grid-row: 11 / span 1;
  grid-column: 1 / span 2;
}

#search-box-manage-students input {
  width: 100% !important;
  height: 100%;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
