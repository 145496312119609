.container {
    display: flex;
    flex-flow: row wrap;
}

.left-panel {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-holder {
    height: 210px;
    width: 210px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 0 50px 0 rgba(0, 0, 0, .1);
    overflow: hidden;
}

.logo-holder:hover {
    opacity: .7;
    cursor: pointer;
}

.right-panel {
    width: 50%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}