.head {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    position: relative;
    gap: 0.5em;
    height: 100%;
}

.divider1 {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    border-right: 0 solid black;
}

.donut_chart {
    height: 65px;
    display: flex;
    align-items: center;
}
