.btn {
    /*min-width: 25px;*/
    /*max-width: 25px;*/
    /*min-height: 25px;*/
    /*max-height: 25px;*/
    /*border-radius: 50%;*/
    /*border: 1px solid #1B3E4D;*/
}

.btn_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
}

button:active {
    transform: translateY(5px);
}
.float_img {
    pointer-events: auto;
}
.float_img:hover {
    cursor: move;
}

.float_close_btn:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.close-pointer {
    cursor: pointer;
}