.active {


}

.active_completed {
    background-color: #89b481;
}

.active_uncompleted {
    background-color: #E5B343;
}

.active_completed, .active_uncompleted, .inactive {
    max-width: 115px;
    min-width: 115px;
    height: 97px;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    color: #fff;
}

.active_completed:hover, .active_uncompleted:hover {
    background-color: #628290;
    cursor: pointer;
}

.inactive {
    background-color: #b0b0b0;
    pointer-events: none;
}

.active:hover {

}
