.eap__management-card-container {
    border-radius: 20px 20px 20px 20px;
    /* background-color: blueviolet; */
    padding: 28px;
}

.eap__management-card-title {
    align-items: center;
    justify-content: space-between;
}

.eap__management-body {
    display: block;
}

.eap__management-action {
    text-align: end;
}

.eap__icon-item-wrapper {
    display: flex;
    border-radius: 58px;
    max-width: 58px;
    max-height: 58px;
    min-width: 58px;
    min-height: 58px;
    align-items: center;
    text-align: center;
}

.eap__icon-item-wrapper svg {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.eap__management-body-announcements {
    /* min-height: 440px; */
    display: flex;
    flex-wrap: wrap;
    justify-items: normal;
    gap: 35px;
}

.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}

.nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.clickable:hover {
    cursor: pointer;
    color: #e4a34e;
}

.announcement-title{
    font-size: 1.2em;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}