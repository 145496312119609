.play_btn {
    border-radius: 10px;
    min-width: 102px;
    max-width: 102px;
    min-height: 102px;
    max-height: 102px;
    background-color: #f7f7f7;

    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4d4d4d;
    background-blend-mode: multiply;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
}

.script-content {
    background-color: #f7f7f7;
    border-radius: 20px;
    padding: 2em 4em;
}