
.dropdown-wrapper,
dropdown-wrapper * {
    display: flex;
}

div.dropdown-wrapper {
    z-index: 100;
    max-width: 210px;
    border-radius: 20px;
    height: 48px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    background-color: #ffffff;
    color: #000000;
    /* padding: 16px; */
    padding: 0 4px 0 21px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-wrapper * {
    z-index: 0;
}

.dropbtn {
    background-color: #ffffff;
    color: #000000;
    /* padding: 16px; */
    padding: 0 4px 0 21px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #1b3e4d;
    color: #ffffff;
}

.dropdown_wrapper_btn {
    display: flex;
}

div.dropdown_wrapper_btn {
    z-index: 100;
    min-width: 210px;
    border-radius: 20px;
    height: 48px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    background-color: #ffffff;
    color: #000000;
    /* padding: 16px; */
    padding: 0 4px 0 21px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown_wrapper_btn:hover,
.dropdown_wrapper_btn:focus {
    background-color: #1b3e4d;
    color: #ffffff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #1b3e4d;
    color: #ffffff !important;
}

.dropdown a:hover {
    background-color: #1b3e4d;
    color: #ffffff;
}

/* .dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.dropdown-content div:hover {
  background-color: #1b3e4d;
  color: #ffffff !important;
}

.dropdown div:hover {
  background-color: #1b3e4d;
  color: #ffffff;
} */

.show {
    display: block;
}

.dropdown-text {
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    padding-right:21px;
}

[disabled].dropdown {
    background-color: #333333;
}

.btn:hover {
    cursor: pointer;
}