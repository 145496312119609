.eap__profile-container {
  height: 43px;
  background-color: #456979;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 43px 43px 43px 43px;
}

.eap__profile-container div {
  display: flex;
}

.eap__profile-avatar {
  height: 38px;
  width: 38px;
  overflow: hidden;
  background-color: #ffffff;
  border: 0 none transparent;
  border-radius: 38px 38px 38px 38px;
  margin-left: 3px;
  align-items: center;
}

.eap__profile-avatar svg {
  margin-left: auto;
  margin-right: auto;
  width: 25px;
  height: 25px;
}

.eap__profile-name {
  padding: 8px;
  /*max-width: 100px;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.eap__profile-dropdown {
  height: 30px;
  width: 30px;
  background-color: #1b3e4d;
  margin-right: 6px;
  border-radius: 30px 30px 30px 30px;
  align-items: center;
}

.eap__profile-dropdown svg {
  margin-left: auto;
  margin-right: auto;
}

.eap__profile-menu-container {
  position: absolute;
  top: calc(var(--header-container-height) + 10px);
  right: var(--header-side-padding);
  background-color: #ffffff;
  padding: 40px;
  border-radius: 20px;
  display: grid;
  gap: 20px;
  flex-direction: column;
  /* background-color: #FFFFFF; */
}

@media only screen and (min-width: 600px) {
}

@media only screen and (max-width: 601px) {
  .mobile-profile {
    display: none;
  }
  .eap__profile-name.mobile-profile {
    display: none;
  }
  .eap__profile-dropdown.mobile-profile {
    display: none;
  }
  .eap__profile-menu-container {
    position: absolute;
    top: calc(var(--header-container-height-mobile) + 10px);
    left: 20px;
  
    /* background-color: #FFFFFF; */
  }
}
