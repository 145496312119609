:root {
    --header-container-height: 105px;
    --header-container-height-mobile: 64px;
    --footer-container-height: 105px;
    --footer-container-height-mobile: 64px;
    --header-logo-height: 66px;

    --header-container-color: #1b3e4d;
    --header-side-padding: 87px;
    --header-side-padding-mobile: 34px;

    --container-padding-top-desktop: 15px;
    --container-padding-top-mobile: 15px;

    --container-side-padding: 66px;
    --container-side-padding-mobile: 13px;

    --text-color: #ffffff;
}

body {
    /* background-color: #fff5f5; */
}

body.dark {
    background-color: #000000;
}
select {
    max-width:100%;
    min-width:100%;
}
html,
body,
#root {
    /*height: 100%;*/
}

* {
    text-decoration: none !important;
    transition: 0.5s all;
}

.h5 {
    font-size: 20px;
    font-weight: 100;
}

.h6 {
    font-size: 16px;
}

.eap__management-container-row {
    display: flex;
    flex-direction: row;
    pointer-events: initial;
}


.eap__management-body {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
}

.eap__management-container-col {
    display: flex;
    flex-direction: column;
}

.eap__scroll-view-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
}

.eap__scroll-view-vertical {
    display: flex;
    flex-direction: column;
    gap: 36px;
}


.eap__management-card-container {
    border-radius: 20px 20px 20px 20px;
    background-color: #ffffff;
    padding: 28px;
}

.eap__shadow {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

.eap__rounded-corner {
    border-radius: 20px 20px 20px 20px !important;
}

.main__container {
    max-width: 1366px;
    min-height: calc(100vh - var(--header-container-height) * 2);
    padding-left: var(--container-side-padding);
    padding-right: var(--container-side-padding);
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 601px) {
    .main__container {
        max-width: 1366px;
        min-height: 100vh;
        padding-left: var(--container-side-padding-mobile);
        padding-right: var(--container-side-padding-mobile);
        padding-top: calc(var(--header-container-height-mobile) * 1) !important;
        padding-bottom: 85px;
        /*padding-bottom: var(--container-padding-top-desktop)!important;*/
    }
}