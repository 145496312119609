.container {
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 857px; */

    background-color: #0a3e4e;
    background-image: url(../../../assets/img/planet&stars.png);
    background-size: cover;
    background-repeat: no-repeat;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    /* Add a transition effect (when scrolling - and font size is decreased) */
}

.container * {
    transition: 0.2s;
}

.shrinkLogo svg {
    height: 66px;
    width: auto;

    -webkit-animation: scale-down-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-down-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.custom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg) translateY(-2px);
}

.custom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 106px;
    transform: rotateY(180deg);
}

.shape {
    fill: #f5f5f5;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    max-width: 956px;
}

.contentContainerVertical {
}

.image {
    display: flex;
    justify-content: center;
    max-height: 213px;
    min-height: 66px;
    /* transform: rotate(180deg); */
    transition: 1s;
}

.second {
    color: white;
    font-size: x-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.second p {
    margin-bottom: 40px;
    font-size: 32px;
}

.secondVertical {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.btn1 {
    background: #33d668;
    font-size: 21px;
    height: 50px;
    width: 250px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px grey;
    border: none;
}

.btn1:hover,
.btn2:hover {
    transform: translateY(-2px);
    background: #30c1d6 !important;
    color: white;
}

.btn2 {
    background: inherit;
    font-size: 21px;
    height: 50px;
    width: 250px;
    border-radius: 15px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 10px 0px grey;
}

.btn3 {
    background: inherit;
    font-size: 21px;
    height: 50px;
    width: 250px;
    border-radius: 15px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 10px 0px grey;
}

.btn2:hover, .btn3:hover {
    border-color: #30c1d6;
    transform: translateY(-2px);
    background: #30c1d6 !important;
}
.btn1, .btn2, .btn3 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media screen and (max-width: 970px) {
    .contentContainer {
        flex-direction: column;
        /* height: 56px; */
    }

    .image {
        width: 100%;
    }

    .shrinkLogo {
        display: none;
    }

    .container {
        height: 100vh;
    }

    .btn1,
    .btn2, .btn3 {

        width: 100%;
        font-size: 1em;
    }
}

@media only screen and (max-width: 810px) {
    .shrinkLogo {
        display: none;
    }

    .contentContainer {
        justify-content: space-around;
    }

    .btn1,
    .btn2, .btn3 {
        width: 100%;
        font-size: 0.8em;
    }
}

.eap_title {
    white-space: nowrap;
    font-size: 32px;
}

@media only screen and (max-width: 600px) {
    .second p {
        margin-bottom: 40px;
        font-size: 70%;
        margin-top: 30px;
    }
}
