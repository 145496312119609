.rotate-center {
    -webkit-animation: rotate-center 0.6s ease-in-out both infinite;
            animation: rotate-center 0.6s ease-in-out both infinite;
  }

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.slide-left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
	-webkit-animation: slide-left 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);

  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-left {
    0% {
        -webkit-transform: translateX(-300%);
        transform: translateX(-300%);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.scale-down-right {
	-webkit-animation: scale-down-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-right 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-down-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}

@keyframes scale-down-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
