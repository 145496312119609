@font-face {
    font-family: Futura;
    src: url(../font/futura\ light\ bt.ttf);
}

@font-face {
    font-family: Futura-Regular;
    src: url(../font/Futura\ Book\ font.ttf);
}

@font-face {
    font-family: Futura-Heavy;
    src: url(../font/Futura\ Bold\ font.ttf);
}

@font-face {
    font-family: Futura-Medium;
    src: url(../font/futura\ medium\ bt.ttf);
}

@font-face {
    font-family: Futura-Light;
    src: url(../font/futura\ light\ bt.ttf);
}

* {
    font-family: Futura-Regular;
    scrollbar-width: 2px;
}

h1 {
    font-family: Futura-Regular !important;
}