.container {
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.inputbox_container {
    display: flex;
    gap: 30px;
    align-items: center;
}

.inputbox {
    display: flex;
    flex: 1;
    border-radius: 14px;
    height: 50px;
}

.postbtn {
    background-color: #64929F;
    color: #fff;
    font-size: 16px;
    height: 50px;
    width: 112px;
    border-radius: 14px;
}

.postbtn:hover {
    transform: translateY(2px);
    opacity: 70%;
    font-size: 20px;
}

.avatar {
    max-height: 60px;
    min-height: 60px;
    max-width: 60px;
    min-width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.comments_container {
display: flex;
    flex-direction: column;
    gap: 20px;
}

.comment_cell {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: flex-start;

}