input[type="text"] {
  min-height: 47px;
  border-radius: 20px;
  width: 100%;
}
.eap__search-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; */
}
.eap__search-box-wrapper * {
  display: flex;
}
.eap__search-box-wrapper span {
  width: 30px;
  transform: translateX(15px);
  z-index: 1;
}
.eap__search-box-wrapper input {
  transform: translateX(-30px);
  z-index: 0;
  text-indent: 60px;
}
