.cardStyle {
  /* width: 50rem; */
  min-height: 536px;
  /* max-height: 694px; */
  /* min-width: 1042px;
    max-width: 1042px; */
  padding: 59px 78px;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 115px;
  /* margin-right: 25px; */
  border-radius: 30px;
  perspective: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  /* margin: 10px;
  margin-top: 50px; */
}
.leftContainer img{
  object-fit: contain;
}
/* .cardStyle2 {
    width: 50rem;
    max-width: 90%;
    min-height: 35rem;
    padding: 40px;
    padding-top: 60px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-right: 25px;
    border-radius: 30px;
    perspective: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    margin: 10px;
    margin-top: 50px;
  } */
.leftContainer {
  display: flex;
  flex-direction: column;
  /* left: 90px; */
  /* top: -10px; */
  /* position: relative; */
}
.leftContainer2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.left {
  height: 122px;
  /* transform: translateY(78px); */
}
.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  bottom: -60px;
}
.right {
  width: 100px;
}
.text {
  display: flex;
  flex-direction: column;
}
.midContainer {
  display: flex;
  /* padding-top: 90px; */
  padding-top: 15%;
  padding-bottom: 15%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.mb {
  margin-bottom: 20px;
}
.list {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}
@media (max-width: 800px) {
  .cardStyle {
    flex-direction: column;
    padding: 20px;
  }
  .cardStyle2 {
    flex-direction: column;
  }
  .leftContainer {
    display: flex;
    flex-direction: column;
    object-fit: cover;
    position: relative;
  }
  .leftContainer2 {
    display: flex;
    flex-direction: column;
    left: 10px;
    top: -10px;
    position: relative;
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  }
}
