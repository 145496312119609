/* body {
  background-color: #1f1919;
} */
.mySlides {
    display: none;
}

img {
    vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;

}

/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* The dots/bullets/indicators */
.dot {
    height: 14px;
    width: 14px;
    margin: 0 2px;
    background-color: transparent;
    border: 1px solid #1b3e4d;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot:hover {
    cursor: pointer;
    border: 3px solid #e4a34e;
}

.dot_active {
    background-color: #1b3e4d;
    height: 14px;
    width: 14px;
    margin: 0 2px;
    border: 1px solid #1b3e4d;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.active {
    background-color: #1f1919;
}

.slider_title {
    font-size: 80px;
    text-transform: uppercase;
    height: 100%;
}

/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2.5s;
    animation-name: fade;
    animation-duration: 2.5s;
}

.slider_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 1px 8px 0 rgba(27, 24, 24, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.05);
    min-height: 385px;
    justify-content: space-around;
    padding: 0 6%;
    gap: 50px;
    background-color: #fff;
}

.left {
}

.mid {
    min-height: 100%;
    background-image: url("../../../assets/svg/slider_bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    flex: 1;
}

.spacer {
    display: flex;
    flex: 1;
}

@media screen and (max-width: 997px) {
    .slider_container {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        align-items: flex-start;
    }

    .slider_container * {
        white-space: nowrap !important;
        overflow: hidden !important;
    }

    .slider_title {
        order: 1;
        font-size: 53px;
        font-family: Futura-Regular, serif;
    }

    .slider_content {
        order: 3;
        max-width: 100%;
    }

    .slider_content h1 {
        font-size: 25px;
    }

    .slider_content h3 {
        font-size: 16px;
    }

    .spacer {
        flex: 2;
    }

    .dot_container {
        order: 4;
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
    }
}

.forbidden:hover {
    cursor: not-allowed;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes fade {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px;
    }
}

@media only screen and (max-width: 440px) {
    .slider_container {
        max-height: 368px;
        min-height: 368px;
        gap: 10px;
    }

    .slider_title {
        order: 1;
        font-size: 53px;
        font-family: Futura-Regular;
    }
}
