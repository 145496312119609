.eap__subheader {
  /* top: var(--header-container-height); */
  margin-top:var(--header-container-height);
  width: 100%;
  display: flex;
  padding-left: var(--header-side-padding);
  padding-right: var(--header-side-padding);
  /* justify-content: space-between; */
  height: var(--header-container-height);
  /* background-color: var(--header-container-color); */
  background-color: #e5e7e8;
  align-items: center;
  z-index: 10000;
}
.eap__subheader-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eap__subheader-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 36px;
}
.eap__subheader-item span {
  /* font-size: 16px; */
  display: none;
}
.eap__subheader-svg-1 {
  width: 36px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
}
.eap__subheader-svg-2 {
  width: 30px;
  height: 30px;

  margin-left: auto;
  margin-right: auto;
}
.eap__subheader-svg-3 {
  width: 30px;
  height: 33px;

  margin-left: auto;
  margin-right: auto;
}
.eap__subheader-btn {
  margin-right: 15px;
  border-radius: 58px 58px 58px 58px;
  width: 58px;
  height: 58px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 601px) {
  .eap__subheader {
    align-items: center;
    justify-content: center;
    position: fixed!important;
    bottom: 0!important;
    /* top: calc(100% - var(--header-container-height-mobile)); */
    height: var(--header-container-height-mobile);
    /* transform: var(--header-container-height-mobile); */
    /* margin-bottom: 100px; */
    /* bottom:  0 !important; */
    /* transform:translateY(100vh - var(--header-container-height-mobile)); */
    /* top: (calc(100vh -  var(--header-container-height-mobile)*2.5)); */
    /* top: calc(100vh - var(--header-container-height-mobile)); */
    /* bottom: 0; */
  }
  .eap__subheader * > .eap__icon-btn-text {
    /* display: none; */
    font-size: 10px;
    white-space: nowrap;
    margin-left: 0;
    margin-top: 3px;
  }
  .eap__subheader .eap__icon-btn-container * {
    display: flex;
  }
  .eap__subheader .eap__icon-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .eap__subheader-item-container {
    display: flex;
    width: 100vh;
    /* justify-items: center; */
    justify-content: space-between;
  }
  .eap__subheader-item-container * {
    margin: 0;
    display: flex;
    align-items: center;
  }
}
